<template>
  <div>
    <router-link tag="a" to="/app">App</router-link>
  </div>
</template>

<script>
export default {
  name: 'Index',
}
</script>

<style scoped>
</style>
