import { render, staticRenderFns } from "./Notebook.vue?vue&type=template&id=33c08afd&v-if=folders%20%26%26%20folders.children%20%26%26%20folders.children.length&"
import script from "./Notebook.vue?vue&type=script&lang=js&"
export * from "./Notebook.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports